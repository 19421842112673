import axios from 'axios';
import { getOrCreateEnrollmentId } from './enrollmentUtil';
import { logger } from '../../logger';
import { removePIIDataFromPayload } from '../../../src/transformers/contactInfo.transform';
import { PROFILE_VERIFY_TOKEN_KEY } from '../../constants';

const get = async (url: string): Promise<any> => {
  try {
    const response = await axios.get('/api/cdp-api', {
      url,
    });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: {},
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: {},
    });
    throw error;
  }
};

const post = async (url: string, payload: any, isCR = false): Promise<any> => {
  const data = isCR ? {} : { enrollmentId: getOrCreateEnrollmentId(), ...payload };
  try {
    const response = await axios.post('/api/cdp-api', {
      url,
      data,
    });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: data,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: data,
    });
    throw error;
  }
};

const put = async (url: string, payload: any): Promise<any> => {
  const data = { enrollmentId: getOrCreateEnrollmentId(), ...payload };
  try {
    const response = await axios.put('/api/cdp-api', {
      url,
      data,
    });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: data,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: data,
    });
    throw error;
  }
};

const savePatientDelegateDetailsToCDP = async (
  url: string,
  payload: any,
  source: string
): Promise<any> => {
  const payloadWithoutPIIData = removePIIDataFromPayload(payload);
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    const myProfileVerificationToken = response?.data?.myProfileVerificationToken;
    if (myProfileVerificationToken && source === 'CR') {
      sessionStorage.setItem(PROFILE_VERIFY_TOKEN_KEY, myProfileVerificationToken);
    }
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payloadWithoutPIIData,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payloadWithoutPIIData,
    });
    throw error;
  }
};
const decryptTokenForResumeJourney = async (url: string, payload: any): Promise<any> => {
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};
const checkForResumeUserForTALevelEPR = async (url: string, payload: any): Promise<any> => {
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};

const updateParticipantType = async (
  guestRef: string,
  enrollmentId: string,
  participantType: string,
  guestType = 'visitor'
): Promise<void> => {
  const payload = {
    enrollmentId: enrollmentId,
    participantType: participantType,
  };
  const url = `/api/contacts/UpdateParticipantType/${guestRef}?guestType=${guestType}`;
  return await post(url, payload);
};

const addOrUpdateCrRegisterUser = async (url: string, payload: any): Promise<any> => {
  try {
    const response = axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};

const verifyMyProfileEmail = async (url: string, payload: any): Promise<any> => {
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};

const fetchProfileDetailsForCR = async (verificationToken: string): Promise<any> => {
  const url = `/api/FetchProfileDetailsForCR`;
  const payload = {
    verificationToken,
  };
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};

const prePopulateEprContactDetails = async (
  verificationToken: string,
  tenantkey: string
): Promise<any> => {
  const url = `/api/PrePopulateEprContactDetails`;
  const payload = {
    verificationToken,
    tenantkey,
  };
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};
const updateSavedTrialsForMyProfile = async (payload: any): Promise<any> => {
  const url = `/api/contacts/UpdateSavedTrialsForMyProfile`;
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};
const updateBrowserIdForMigratedUser = async (payload: any): Promise<any> => {
  const url = `/api/contacts/UpdateBrowserIdForMigratedUsers`;
  try {
    const response = await axios.post('/api/cdp-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_CDP_API_BASE_URL}${url}`, {
      error: error?.message,
      payload: payload,
    });
    throw error;
  }
};

export {
  get,
  post,
  put,
  updateParticipantType,
  savePatientDelegateDetailsToCDP,
  decryptTokenForResumeJourney,
  addOrUpdateCrRegisterUser,
  checkForResumeUserForTALevelEPR,
  verifyMyProfileEmail,
  fetchProfileDetailsForCR,
  updateSavedTrialsForMyProfile,
  prePopulateEprContactDetails,
  updateBrowserIdForMigratedUser,
};
