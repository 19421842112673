import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: any = {
  isLoading: false,
  isHomePageLoaded: false,
  isTextLoader: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    hideLoader: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    setHomePageLoad: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isHomePageLoaded: action.payload,
    }),
  },
});

export const { showLoader, hideLoader, setHomePageLoad } = loaderSlice.actions;
export default loaderSlice.reducer;
